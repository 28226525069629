import React from 'react'
import get from 'lodash.get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import ProductSlider from 'blocks/ProductSlider'
import Videos from 'blocks/Videos'
import BlogArticles from './BlogArticles'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { isUrlInDomain } from 'helpers/url'
import ProductVideo from '../Product/ProductVideo'

import { Spacing, Container, SharedStyles } from '../../kenra-storybook/index'
const { StTitle, StPageTitle, StImgDesc, StInnerTextNoIndent } = SharedStyles


const BlogPost = ({ intl, page }) => {
    const {
        topic,
        title,
        text,
        products,
        videos,
        videosImage,
        author,
        authorImage,
    } = page

    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: node => {
                let id = get(node, 'data.target.sys.id')
                let asset = assetMap.get(id)
                let img = asset.url //get(node, 'data.target.fields.file["en-US"].url');
                let title = asset.title //get(node, 'data.target.fields.title["en-US"]');
                let href = asset.description // get(node, 'data.target.fields.description["en-US"]');

                if (!img) return null

                if (
                    href &&
                    href.length > 0 &&
                    (!href.substring(1) === '/' ||
                        !href.substring(4) === 'http')
                ) {
                    href = null
                }

                return (
                    <StImgDesc>
                        <a href={href}>
                            <img src={img} alt={title} />
                            {/* <figcaption>{title}</figcaption> */}
                        </a>
                    </StImgDesc>
                )
            },
            [BLOCKS.PARAGRAPH]: paragraph => {
                let content = get(paragraph, 'content[0].value')
                let type = get(paragraph, 'content[0].marks[0].type')
                let isCode = type === 'code'
                const isVideoLink = content.includes('[[video]]=')
                if (isCode) {
                    return (
                        <div
                            style={{
                                paddingTop: 20,
                                paddingBottom: 20,
                                alignItems: 'center',
                                flexDirection: 'row',
                                flex: 1,
                                justifyItems: 'center',
                            }}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    )
                } else if (isVideoLink) {
                    const parts = content.split('=')
                    if (parts && parts.length > 0) {
                        try {
                            const json = JSON.parse(parts[1])
                            return (<ProductVideo hideTitle={!json.title} video={{ youtubeLink: json.link }} title={json.title} />)
                        } catch (error) {
                            console.log(title, content, parts, error)
                            return null
                        }
                    } else {
                        return null
                    }
                } else if (
                    paragraph &&
                    paragraph.content &&
                    paragraph.content.length > 0
                ) {
                    let paragraphContent = '<p>'
                    paragraph.content.forEach(item => {
                        if (item.nodeType === 'text') {
                            let startDecoration = ''
                            let endDecoration = ''
                            item.marks.forEach(mark => {
                                if (mark.type === 'bold') {
                                    startDecoration += '<b>'
                                    endDecoration = '</b>' + endDecoration
                                } else if (mark.type === 'italic') {
                                    startDecoration += '<i>'
                                    endDecoration = '</i>' + endDecoration
                                } else if (mark.type === 'underline') {
                                    startDecoration += '<u>'
                                    endDecoration = '</u>' + endDecoration
                                }
                            })
                            let itemText = get(item, 'value')
                            itemText = itemText.replace(
                                /(\r\n|\n|\r)/g,
                                '<br />'
                            )
                            paragraphContent +=
                                startDecoration +
                                itemText +
                                endDecoration +
                                ' '
                        } else if (item.nodeType === 'hyperlink') {
                            let destination = get(item, 'data.uri')
                            if (
                                destination !== null &&
                                destination.startsWith('http')
                            ) {
                                let urlParts = destination.split('/')
                                if (isUrlInDomain(urlParts[2])) {
                                    destination = destination.replace(
                                        /.*\/\/[^\/]*/,
                                        ''
                                    )
                                    var localeUrl = '/' + intl.locale
                                    if (intl.locale === intl.defaultLocale) {
                                        localeUrl = ''
                                    }

                                    destination = `${localeUrl}${destination}`
                                }
                            }
                            let value = get(item, 'content[0].value')
                            paragraphContent += `<a href=${destination}><u>${value}</u></a>`
                        }
                    })
                    paragraphContent += '</p>'
                    return (
                        <div
                            style={{
                                paddingTop: 20,
                                paddingBottom: 20,
                                alignItems: 'center',
                                flexDirection: 'row',
                                flex: 1,
                                justifyItems: 'center',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: paragraphContent,
                            }}
                        />
                    )
                } else {
                    return null
                }
            },
        },
    }
    const assetMap = new Map()
    const renderOptions = function (links) {

        for (const asset of links) {
            assetMap.set(asset.contentful_id, asset)
        }
        return options
    }
    const imgSize = '80px'
    return (
        <>
            <Spacing removeSpaceBottom>
                <Container>
                    <StPageTitle>
                        {/* <span>{topic}</span> */}
                        <StTitle>
                            <h1>{title}</h1>
                        </StTitle>
                        <span style={{ marginTop: '10px' }}>
                            <img
                                style={{
                                    borderRadius: '50%',
                                    height: imgSize,
                                    width: imgSize,
                                    objectFit: 'contain',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                }}
                                src={get(authorImage, 'localFile.publicURL')}
                                alt=""
                            />
                            <h3
                                style={{
                                    display: 'inline',
                                    lineHeight: imgSize,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {`By: ${author}`}
                            </h3>
                        </span>
                    </StPageTitle>

                    <StInnerTextNoIndent>
                        {documentToReactComponents(JSON.parse(get(text, 'raw')), renderOptions(get(text, 'references')))}
                    </StInnerTextNoIndent>
                </Container>
            </Spacing>

            {videos && (
                <Spacing>
                    <Videos
                        videos={videos}
                        title={'Related Videos'}
                        showBottomButton={false}
                        bgImage={get(videosImage, 'localFile.publicURL')}
                    />
                </Spacing>
            )}

            {products && products.length > 0 && (
                <Spacing>
                    <ProductSlider
                        isOnBlog={true}
                        productHandles={products.map(
                            p => p.shopifyProduct.handle
                        )}
                    />
                </Spacing>
            )}

            <Spacing>
                <BlogArticles />
            </Spacing>
        </>
    )
}

export default injectIntl(BlogPost)
